import { HLocation } from '@reach/router';
import React from 'react';
import { Layout, SEO } from '@components';

import '@styles/3_pages/404.scss';

interface NotFoundProps {
  location: HLocation;
}

function NotFound({ location }: NotFoundProps) {
  return (
    <Layout location={location}>
      <SEO />
      <div className="container-404">
        <h1 className="container-404__content__heading">Not Found</h1>
        <p className="container-404__content__para">
          This page doesn&apos;t exist...
        </p>
      </div>
    </Layout>
  );
}

export default NotFound;
export { NotFound };
